<div class="container" >
    <header>
        <section>
        <h1 style="color: white;">One Way Drop Taxi Services with One Drop Cab</h1>
        <h2 style="color: white; ">Your trusted transportation partner across cities and districts</h2>
    </section>
    </header>
    
    <section >
        <h2 style="color: white;">Explore Our Reliable One Way Drop Taxi Services</h2>
        <p style="color: white;">
            Welcome to One Drop Cab, your go-to choice for reliable and convenient One Way Drop Taxi services in South India. With a strong presence in cities and districts across Tamil Nadu, Karnataka, Puducherry, and Andhra Pradesh, we ensure seamless transportation for your journeys.
        </p>
        <p style="color: white;">
            Whether you are exploring the vibrant streets of Chennai, the bustling cityscape of Bangalore, the historic charm of Madurai, or the serene districts of Puducherry, our dedicated One Way Drop Taxi services are designed to meet your travel needs with comfort and reliability.
        </p>
    </section>
    
    <!-- <section *ngFor="let route of selectedRoutes; let position=index;"  class="row col-md-12">
      <div class="row">
              <h2 style="color: #FFB300;">One Way Drop Taxi From {{ route.name.replace("-"," ") }}</h2>
              <div *ngFor="let routeDetails of route.city" style="line-height: 2.0em; font-size: medium;" class="col-md-3 footer-list">
                <li><a [href]="'service-areas/city/'+route.name.toLowerCase().replace(' ','-')+'/'+routeDetails.title.toLowerCase()">
                  <i class="fas fa-caret-right"></i> 
                  {{routeDetails?.title.replaceAll("-"," ")}}</a></li>
              </div>
  </div>
  
</section> -->
<section  class="row col-md-12">
  <div class="row">
        <!-- <div *ngFor="let city of getCityNames(route)" class="row"> -->
          <h2 style="color: #FFB300;">One Way Drop Taxi - {{transform(district)}}</h2>
          <div *ngFor="let routeDetails of selectedRoutes" style="line-height: 2.0em; font-size: medium;" class="col-md-6 footer-list">
            <h2><li><a [href]="'service-areas/city/'+district.toLowerCase().replace(' ','-')+'/'+routeDetails.title.toLowerCase()"><i class="fas fa-caret-right"></i> 
              {{routeDetails?.title.replaceAll("-"," ")}} One Way Drop Taxi
            </a></li></h2>
          </div>
        <!-- </div> -->
</div>

</section>
</div>