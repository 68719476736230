import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, Sanitizer, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

import { SharedModule } from '../shared/Shared.Module';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { AppConstants } from '../shared/app.constants';
declare var $: any;

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss'
})
export class ServiceComponent implements AfterViewInit, OnInit{
  app: any;
  selectedRoutes: any=[];
  selectedAirports: any=[];
  district: string;
  
  SEOKey="One Drop Cab Service, One-Way Transportation, Affordable Cabs, Punctual Cab Service, Stress-Free Travel, Book One Drop Cab, Chennai Taxi Service";
  SEODescription= "Welcome to OneDropCab. – Your premier destination for hassle-free and reliable transportation. One Drop Cab provides One Way / Round Trip Cab service at affordable cost.";
  SEOTitle="One Drop Cab Service - Affordable & Stress-Free Transportation - OneDropCab"
  // displayedColumns = ['title'];
  // ELEMENT_DATA: PeriodicElement[] = [];
  // dataSource : any;
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(@Inject(PLATFORM_ID) private platformId: string,private router: Router,private route: ActivatedRoute, public sanitizer: DomSanitizer, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private doc)
  {    
    if(isPlatformBrowser(this.platformId))
    {
      
    }
    this.createLinkAndMetas(this.SEOTitle, this.SEOKey, this.SEODescription, true, false);
  }
  ngOnInit(): void {
    this.route.params.subscribe((res:any)=>{
      this.district = res.city;
      // this.state  = res.state;
      if(!this.district)
      {
        this.district="Salem";
      }
    })

    if (this.district) {
      if(isPlatformBrowser(this.platformId))
      {
        this.getCity();
      }  
    } 
  }
  ngAfterViewInit(): void {
    

  }
getCity(){
  
    let jsonPayload={"action":"fetchroutes",
    "city":this.district,
    "type":"cityroutes"
    }
    fetch(AppConstants.APP_URL,{
      method:'POST',
      body:JSON.stringify(jsonPayload)}).then((response) => response.json())
    .then((resData) =>{ 
      this.selectedRoutes = resData;
      this.hideLoader();
    })
  
  }

 
  transform(value: string): string {
    return value.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  createLinkAndMetas(title: string, keywords: string, description: string, isCanonicalRequired: boolean, isLocation: boolean) {

    this.titleService.setTitle(title);
    var keys = "";
    var prekeys=keywords.split(",");
    if(isLocation)
    {
      var loc = this.doc.URL.split("/")[4];
      for(var i=0;i< prekeys.length; i++)
        {      
          keys+=prekeys[i]+" in "+loc+", "
        }
        keys = keys.substring(0,keys.length-1);
    }
    else{
      keys  = keywords
    }
    
    keys = keys.toLocaleLowerCase();
    this.metaService.updateTag(              
    {
      name: 'description', content: description
    })
    this.metaService.updateTag(              
    {
      name: 'keywords', content: keys
    })
    
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'og:image:alt', content:title });
    this.metaService.updateTag({ property: 'twitter:title', content: title });
    this.metaService.updateTag({ property: 'twitter:description', content: description });
    this.metaService.updateTag({ property: 'twitter:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'twitter:url', content:window.location.origin});
    this.metaService.updateTag({ property: 'twitter:site', content:'One Drop Cab'});
    if(isCanonicalRequired &&isPlatformBrowser(this.platformId))
    {
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }
 }
 hideLoader(){
  if(isPlatformBrowser(this.platformId))  
  {
    setTimeout(() => {
        $('.preloader').fadeOut();
    }, 50);
  }
}
}
